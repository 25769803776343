export default {
    data() {
        return {
            rightList: [
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮智慧班牌应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮智慧校园应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁业余大学管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校智慧校园应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园统一认证应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园智慧考勤应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园智慧环境感知应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园智慧物联平台移动端应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园智慧物联平台应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁嗨会会议屏幕应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁教师专业发展应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁网站集群应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮区域教育云应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮智慧微校应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校园德育管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁新媒体内容管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁学生综合素质评价应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁影像作品管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁资源管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁走班选课应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校统一身份认证平台软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校校园门户应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校学工管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校智慧校园移动端软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: 'IoT智慧校园管理平台应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: 'IoT智慧校园客户端应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮教师成长画像应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁校帮学生成长画像应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁智能储物柜应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁低代码开发平台应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁智慧生态应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁职业院校学生社区移动端应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁学生成绩分析应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁智慧融合平台应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁智慧园区管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁人才市场管理应用软件'
                },
                {
                    img: require('../../assets/1.jpg'),
                    title: '曜宁高等院校学生成长画像应用软件'
                }
            ],
            certifList: [
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁校帮智慧班牌应用软件'
                },
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁校帮智慧校园应用软件'
                },
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁业余大学管理应用软件'
                },
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁职业院校智慧校园应用软件'
                },
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁校帮学生成长画像应用软件'
                },
                {
                    img: require('../../assets/2.jpg'),
                    title: '曜宁校园智慧考勤应用软件'
                }
            ]
        }
    },
    methods: {
       
    },
    created() {
       
    },
}