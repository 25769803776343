<template>
  <div class="about">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>关于</h1>
          <p>
            <a href="/">首页</a>
            <Icon class="arrow" type="ios-arrow-forward" />关于
          </p>
        </div>
      </div>
      <!-- 公司简介 -->
      <div class="heading">
        <h2 class="introduction">公司简介</h2>
      </div>
      <div class="auto-container">
        <p>
          <strong>上海曜宁信息科技有限公司</strong>
          是专业从事中小幼、中高职智慧校园软件开发的企业，公司技术实力雄厚，智慧教育产品多样，除了强大的智慧校园软件产品外、校园智慧物联也在教育软件领域别具一格，公司智慧教育产品既有自己个性的一面，同时又和阿里钉钉架构、微信公众号小程序、企业微信等第三方平台高度融合，互为补充，我们致力于成为最优秀的教育软件供应商。
        </p>
        <img
          src="https://www.rayscloud.cn/webapi/upload/editor/2022/02/21/254bc593-e97a-4f97-849c-26f205e1c1e3_O.png"
          alt=""
        />
        <p style="padding-bottom: 1rem"><strong>人员构成</strong></p>
        <p style="padding-bottom: 1rem">
          公司拥有上海、常州二个研发中心，专职研发人员20余名，核心研发成员均来教育系统信息技术岗位，具备国内知名教育行业
          IT 业任职经历；
        </p>
        <p style="padding-bottom: 1rem"><strong>技术优势</strong></p>
        <p>
          公司拥有校帮统一认证、校帮智搭、师生画像、智慧屏幕、校智联、智慧生态等业内领先的软硬件技术平台，同时拥有多项基于大数据和Ai的领先技术；
        </p>
        <img
          src="https://www.rayscloud.cn/webapi/upload/editor/2022/02/21/7edc6f01-3357-4ca3-ab5f-602067d4e37b_O.png"
          alt=""
        />
      </div>
      <!-- 计算机软件著作权 -->
      <div class="bg">
        <div class="heading">
          <p class="bottom">COPYRIGHT</p>
          <h2 class="copyRight">计算机软件著作权</h2>
        </div>
        <div class="auto-container">
          <Row>
            <Col span="4" v-for="(item, index) in rightList" :key="index">
              <div class="rightList">
                <img :src="item.img" alt="" />
                <p>{{ item.title }}</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <!-- 软件产品证书 -->
      <div class="heading">
        <h2 class="certificate">软件产品证书</h2>
      </div>
      <div class="auto-container certifList">
        <Row>
          <Col span="4" v-for="(item, index) in certifList" :key="index">
            <div class="rightList">
              <img :src="item.img" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>
<script>
import about from "./index.js";
export default about;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>